import React, { useEffect, useRef, useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { TFunction } from 'i18next';
import { Business } from 'utils/types';
import Select from 'components/Forms/FormComponents/Select';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutocompleteDropdown from 'components/Forms/FormComponents/AutocompleteDropdown';

type PromotionWeeks = {
	c_list: string;
	week_from: string;
	week_to: string;
};

export default function ListInformationForm(props: {
	cList: string;
	setCList: (val: string) => void;
	editDisabledByStatus: boolean;
	listInfoSaved: boolean;
	setListInfoSaved: (val: boolean) => void;
	listWeekFrom: string;
	setListWeekFrom: (val: string) => void;
	listWeekTo: string;
	setListWeekTo: (val: string) => void;
	t: TFunction<'translation', undefined>;
	submitListInfo: () => void;
	isNew: boolean;
	businesses: Business[];
	iBusiness: number | null;
	setIBusiness: (val: number) => void;
	listStatus: string;
}) {
	const {
		cList,
		setCList,
		editDisabledByStatus,
		listInfoSaved,
		setListInfoSaved,
		listWeekFrom,
		setListWeekFrom,
		listWeekTo,
		setListWeekTo,
		t,
		submitListInfo,
		isNew,
		businesses,
		iBusiness,
		setIBusiness,
		listStatus
	} = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isDropdownFocused, setIsDropdownFocused] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState([] as PromotionWeeks[]);

	function setValueOnInput(value: string, setFunc: (val: string) => void) {
		setListInfoSaved(false);
		setFunc(value);
	}

	const [formEditable, setFormEditable] = useState(false);
	const [weekEditable, setWeekEditable] = useState(true);

	const businessMap = new Map<string, string>(
		businesses.map((business) => [business.id.toString(), business.display_value])
	);

	async function fetchPromotionWeeks(c_list: string) {
		setWeekEditable(true);
		if (!weekEditable) {
			setListWeekFrom('');
			setListWeekTo('');
		}

		if (c_list.length < 4) {
			return;
		}

		console.log('fetchPromotionWeeks', c_list);

		const response = await sendHttpRequest(
			'GET',
			`/promotion/weeks/${c_list}`,
			null,
			navigate,
			dispatch
		);

		setDropdownOptions(response.data);
		if (response.data.length > 0) {
			setIsDropdownFocused(true);
		}
	}

	function onPromotionWeeksSelected(pw: PromotionWeeks) {
		setCList(pw.c_list);
		setListWeekFrom(pw.week_from);
		setListWeekTo(pw.week_to);
		setListInfoSaved(false);
		setWeekEditable(false);
		setIsDropdownFocused(false);
	}

	useEffect(() => {
		setFormEditable(!editDisabledByStatus && listStatus === 'BUILD');
	}, [editDisabledByStatus, listStatus]);

	return (
		<>
			<Select
				customClass="i_business-select"
				required={true}
				options={businessMap}
				defaultValue={iBusiness ? iBusiness.toString() : undefined}
				name="i_business"
				label={t('promotion.fields.business')}
				disabled={!isNew}
				onChange={(e) => setIBusiness(parseInt(e.target.value))}
			/>
			<AutocompleteDropdown<PromotionWeeks>
				autocompleteOptions={dropdownOptions}
				onOptionPick={onPromotionWeeksSelected}
				optionRenderer={(pw: PromotionWeeks) => (
					<>
						<span className="font-small">
							<b>{pw.c_list}</b>
						</span>
						<span className="font-tiny ms-2 text-end">
							{pw.week_from} - {pw.week_to}
						</span>
					</>
				)}
				inputId="c_list"
				disabled={!formEditable}
				inputValue={cList}
				label={t('promotion.fields.promotion_name')}
				setInputValue={(val: string) => {
					setCList(val);
					setListInfoSaved(false);
					fetchPromotionWeeks(val);
				}}
				isFocused={isDropdownFocused}
				setIsFocused={setIsDropdownFocused}
			/>
			<Input
				type="number"
				thousandsSeparator={null}
				maxLength={6}
				required={true}
				disabled={!formEditable || !weekEditable}
				defaultValue={listWeekFrom}
				label={t('promotion.fields.week_from')}
				name="week_from"
				onInput={(e) => setValueOnInput(e.target.value, setListWeekFrom)}
				customClass="text-end promotion-week-input"
			/>
			<Input
				type="number"
				thousandsSeparator={null}
				maxLength={6}
				required={true}
				disabled={!formEditable || !weekEditable}
				defaultValue={listWeekTo}
				label={t('promotion.fields.week_to')}
				name="week_to"
				onInput={(e) => setValueOnInput(e.target.value, setListWeekTo)}
				customClass="text-end promotion-week-input"
			/>
			{formEditable && (
				<button
					type="button"
					className="btn btn-primary"
					onClick={submitListInfo}
					disabled={
						listInfoSaved ||
						cList === '' ||
						listWeekFrom === '' ||
						listWeekTo === '' ||
						(isNew && [0, null].includes(iBusiness))
					}>
					{t('promotion.fields.save')}
				</button>
			)}
			{!formEditable && <div className="instead-of-save-btn" />}
		</>
	);
}
