import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input from 'components/Forms/FormComponents/Input';
import Select from 'components/Forms/FormComponents/Select';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function PromotionTranslationModal(props: {
	modalOpened: boolean;
	setModalOpened: (opened: boolean) => void;
	languageList: string[];
	i_business: number;
	translatedTexts: (texts: Map<string, string>) => void;
	textToTranslate: string;
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [textToTranslate, setTextToTranslate] = useState('');
	const [sourceLanguage, setSourceLanguage] = useState('en');
	const [loading, setLoading] = useState(false);

	const languageOptions = useMemo(() => {
		const res = new Map<string, string>();

		res.set('en', t('general.language.en'));

		props.languageList.forEach((language) => {
			res.set(language, t('general.language.' + language));
		});

		return res;
	}, [props.languageList, t]);

	useEffect(() => {
		setTextToTranslate(props.textToTranslate);
	}, [props.textToTranslate]);

	async function translate() {
		const translation_response = await sendHttpRequest(
			'POST',
			'/translations/translate-product',
			{ i_business: props.i_business, phrase: textToTranslate, lang_from: sourceLanguage },
			navigate,
			dispatch
		);

		const translations = translation_response.data.translations;
		const res = new Map<string, string>();
		translations.forEach((t1: { name: string; description: string }) => {
			res.set(t1.name.toLowerCase(), t1.description);
		});

		props.translatedTexts(res);
	}

	return (
		<>
			<Modal show={props.modalOpened} onHide={() => props.setModalOpened(false)}>
				<Modal.Header closeButton>
					<h2 className="fs-5 m-0">{t('general.phrases.translate')}</h2>
				</Modal.Header>

				<Modal.Body>
					<Input
						type={'text'}
						name={'textToTranslate'}
						label={t('general.phrases.text_to_translate')}
						onChange={(event) => setTextToTranslate(event.target.value)}
						required={true}
						defaultValue={textToTranslate}
						disabled={loading}
					/>

					<Select
						required={true}
						customClass="mt-3"
						name={'sourceLanguage'}
						label={t('general.phrases.source_language')}
						options={languageOptions}
						defaultValue={sourceLanguage}
						onChange={(event) => setSourceLanguage(event.target.value)}
						disabled={loading}
					/>

					<button
						type="button"
						className="btn btn-primary mt-3 col-12 btn-lg"
						onClick={() => {
							setLoading(true);
							translate().then(() => {
								setLoading(false);
								props.setModalOpened(false);
							});
						}}
						disabled={loading}>
						{!loading ? (
							t('general.phrases.translate')
						) : (
							<span
								className="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"></span>
						)}
					</button>
				</Modal.Body>
			</Modal>
		</>
	);
}
