import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { humanizeString, randomHash } from 'utils/string';
import { AppComponent } from 'utils/components';
import { useDispatch, useSelector } from 'react-redux';
import PromotionTemplate from 'components/Forms/Promotion/PromotionTemplate';
import { Dropdown, Modal } from 'react-bootstrap';
import Loading from 'components/parts/Loading';
import Select from 'components/Forms/FormComponents/Select';
import { RootState } from 'state/store';
import Alert from 'components/parts/Alert';
import { useTranslation } from 'react-i18next';
import ListInformationForm from 'components/Forms/Promotion/ListInformationForm';
import GpSummary from 'components/Forms/Promotion/GpSummary';
import { setAllCountries } from 'state/userSlice';
import i18n from 'i18n';
import FileDownload from 'js-file-download';
import {
	Product,
	Promotion,
	PromotionStructure,
	Tab,
	PromotionColumn,
	PromotionViewLayout,
	Business,
	DataSaveStatus,
	GpSummaryRaw,
	GpSummaryProcessed,
	PromotionCountry
} from 'utils/types';
import { setTotalGpPercentage } from 'state/totalGpPercentageSlice';
import { getTotalGpPercentage } from 'utils/promotion';
import Input from 'components/Forms/FormComponents/Input';

export default function ProductsForm(props: { component: AppComponent }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [filtersChanged, setFiltersChanged] = useState(false);
	const [filterProductCodeInput, setFilterProductCodeInput] = useState('');
	const [filterCartonCodeInput, setFilterCartonCodeInput] = useState('');
	const [filterProductCode, setFilterProductCode] = useState('');
	const [filterCartonCode, setFilterCartonCode] = useState('');

	const role = useSelector((state: RootState) => state.user.user?.role);

	const [data, setData] = useState([] as Product[]);
	const [list, setList] = useState(null as null | Promotion);
	const [structures, setStructures] = useState([] as PromotionStructure[]);
	const [loading, setLoading] = useState(true);

	const [layouts, setLayouts] = useState({
		global: [] as PromotionViewLayout[],
		custom: [] as PromotionViewLayout[]
	});
	const [currentLayout, setCurrentLayout] = useState(null as PromotionViewLayout | null);

	const [templates, setTemplates] = useState([] as string[]);
	const [allTemplates, setAllTemplates] = useState([] as { key: string; value: string }[]);
	const [usages, setUsages] = useState([] as string[]);
	const [gpCategories, setGpCategories] = useState([] as string[]);
	const [clusters, setClusters] = useState([] as string[]);
	const [omitColumns, setOmitColumns] = useState([] as string[]);

	const [businesses, setBusinesses] = useState([] as Business[]);
	const [iBusiness, setIBusiness] = useState(
		JSON.parse(props.component.variables ?? '{}').i_business as number
	);
	const [cList, setCList] = useState('');
	const [listWeekFrom, setListWeekFrom] = useState('');
	const [listWeekTo, setListWeekTo] = useState('');
	const [listStatus, setListStatus] = useState('build');
	const [isNew, setIsNew] = useState(true);
	const [statusChanged, setStatusChanged] = useState(false);
	const [editDisabledByStatus, setEditDisabledByStatus] = useState(true);
	const [canClose, setCanClose] = useState(true);
	const [listInfoSaved, setListInfoSaved] = useState(true);
	const [dataSaveStatus, setDataSaveStatus] = useState('Data saved' as DataSaveStatus);
	const [showAddTemplateButton, setShowAddTemplateButton] = useState(false);
	const [originalDataLength, setOriginalDataLength] = useState(0);

	const [gpSummaryRaw, setGpSummaryRaw] = useState([] as GpSummaryRaw[]);
	const [gpSummaryProcessed, setGpSummaryProcessed] = useState([] as GpSummaryProcessed[]);

	const totalGpPercentage = useSelector(
		(state: RootState) => state.totalGpPercentage.totalGpPercentage
	);

	const allCountries = useSelector((state: RootState) => state.user.allCountries);
	const [promotionStatuses, setPromotionStatuses] = useState([] as string[]);

	const [countries, setCountries] = useState(allCountries as string[]);

	const urlParams = new URLSearchParams(window.location.search);
	const i_list = urlParams.get('i_list');
	const action = urlParams.get('action');

	useEffect(() => {
		if (i_list == undefined && window.location.pathname.includes('create')) {
			navigate('/list_detail?action=new');
		}
	}, [i_list, navigate]);

	function toggleColumnVisibility(col_key: string) {
		if (omitColumns.includes(col_key)) {
			changeOmittedColumns(omitColumns.filter((c) => c !== col_key));
		} else {
			changeOmittedColumns([...omitColumns, col_key]);
		}
	}

	function onFilterSubmit() {
		setFilterProductCode(filterProductCodeInput);
		setFilterCartonCode(filterCartonCodeInput);
		setShowFilterModal(false);
		setFiltersChanged(true);
	}

	function onFilterReset() {
		setFilterProductCodeInput('');
		setFilterCartonCodeInput('');
	}

	function hideAllColumns() {
		changeOmittedColumns(
			omitColumns.concat(
				structures[0]?.tabs.flatMap((t: { columns: { key: string }[] }) =>
					t.columns.map((c) => c.key)
				)
			)
		);
	}

	function showAllColumns() {
		changeOmittedColumns([]);
	}

	useEffect(() => {
		sendHttpRequest('GET', '/promotion-layout', { i_business: iBusiness }, navigate, dispatch).then(
			(response) => {
				setLayouts({
					global: response.data[0][0].map(
						(row: { val: string; i_prop: number }) =>
							({
								...JSON.parse(row.val),
								i_prop: row.i_prop
							}) as PromotionViewLayout
					),
					custom: response.data[1][0].map(
						(row: { val: string; i_prop: number }) =>
							({
								...JSON.parse(row.val),
								i_prop: row.i_prop
							}) as PromotionViewLayout
					)
				});
			}
		);
	}, [dispatch, navigate, iBusiness]);

	function saveLayout(global = false) {
		if (!global || role === 'ADMIN') {
			const newLayoutName = prompt('Enter layout name');
			if (!newLayoutName) {
				return;
			}

			const layout = {
				name: newLayoutName,
				omit_columns: omitColumns
			};

			(global ? layouts.global : layouts.custom)
				.filter((l) => l.name === newLayoutName)
				.forEach((l) =>
					sendHttpRequest(
						'DELETE',
						'/promotion-layout',
						{ i_prop: l.i_prop, i_business: iBusiness },
						navigate,
						dispatch
					)
				);

			sendHttpRequest(
				'POST',
				'/promotion-layout',
				{ i_business: iBusiness, layout: layout, global: global },
				navigate,
				dispatch
			).then((response) => {
				const data: { i_prop: number; val: string } = response.data[0][0];
				const newLayout: PromotionViewLayout = {
					...JSON.parse(data.val),
					i_prop: data.i_prop
				};

				setLayouts({
					global: global
						? [...layouts.global.filter((l) => l.name !== newLayoutName), newLayout]
						: layouts.global,
					custom: !global
						? [...layouts.custom.filter((l) => l.name !== newLayoutName), newLayout]
						: layouts.custom
				});

				setCurrentLayout(newLayout);
			});
		}
	}

	function loadLayout(layout: PromotionViewLayout) {
		setOmitColumns(layout.omit_columns);
		setCurrentLayout(layout);
	}
	function deleteLayout(layout: PromotionViewLayout, global = false) {
		if (!global || role === 'ADMIN') {
			const confirmation = confirm(
				'Are you sure you want to delete the layout "' + layout.name + '"?'
			);
			if (!confirmation) {
				return;
			}
			sendHttpRequest(
				'DELETE',
				'/promotion-layout',
				{ i_prop: layout.i_prop, i_business: iBusiness, global: global },
				navigate,
				dispatch
			).then(() => {
				if (currentLayout == layout) {
					setCurrentLayout(null);
					setOmitColumns([]);
				}
				setLayouts({
					global: global
						? layouts.global.filter((l) => l.i_prop !== layout.i_prop)
						: layouts.global,
					custom: !global
						? layouts.custom.filter((l) => l.i_prop !== layout.i_prop)
						: layouts.custom
				});
			});
		}
	}

	function changeOmittedColumns(c: string[]) {
		setOmitColumns(c);
		setCurrentLayout(null);
	}

	function addProduct(template: string) {
		data.push({
			i_product: null,
			action: null,
			i_template: template,
			i_usg: '',
			GP_group: '',
			disable_wop_s: false,
			disable_wop_st: false,
			disable_wop_es: false,
			main_cluster: '',
			items: [
				{
					c_item: '',
					i_item: '',
					n_item: '',
					quantity: 1,
					srp: '0.00',
					countries: []
				}
			],
			product_price_code: '',
			product_short_code: null,
			product_JDE_status: 'NEW',
			retail_price: '',
			id: randomHash(),
			countries: [],
			valid_from: '',
			valid_to: '',
			cancelled: false,
			last_modification: ''
		});
		setData([...data]);
		setStatusChanged(false);
	}

	function removeProduct(productId: string, i_template: string) {
		const d = data.filter((row) => row.id !== productId);
		setData([...d]);
		setStatusChanged(false);

		if (
			Array.from(new Set(d.map((row: { i_template: string }) => row.i_template))).length <
			templates.length
		) {
			setTemplates(templates.filter((t) => t !== i_template));
		}
	}

	const [showTemplateModal, setShowTemplateModal] = useState(false);

	const handleCloseTemplateModal = () => setShowTemplateModal(false);
	const handleOpenTemplateModal = () => setShowTemplateModal(true);

	const [newTemplateSelect, setNewTemplateSelect] = useState(undefined as string | undefined);

	function triggerDataRefresh() {
		setStatusChanged(true);
	}

	function addTemplate() {
		if (newTemplateSelect === undefined) {
			return;
		}

		templates.push(newTemplateSelect);
		setNewTemplateSelect(undefined);
		setTemplates([...templates]);
		setShowTemplateModal(false);
		addProduct(newTemplateSelect);
	}

	const convertToColumnDefinitions = useCallback(
		(data: object): PromotionColumn[] => {
			return Object.entries(data).map(([colKey, colValue]) => {
				colValue = colValue[0];
				let type: 'text' | 'number' | 'datetime-local' | 'checkbox' | 'percentage' | 'money' =
					'number';
				switch (colValue.type) {
					case 'datetime':
						type = 'datetime-local';
						break;
					case 'character':
						type = 'text';
						break;
					case 'bit':
						type = 'checkbox';
						break;
					case 'percentage':
						type = 'percentage';
						break;
					case 'money':
						type = 'money';
						break;
				}

				return {
					name: t('promotion.fields.' + colKey.replace('%', 'perc')),
					key: colKey,
					decimal: colValue.decimal,
					currency: colValue.currency,
					type: type,
					definition: colValue.definition,
					disabled: colValue.blocked ?? false,
					need_reload: colValue.need_reload ?? false,
					no_separator: colValue.no_separator ?? false,
					open_for_completed_state: colValue.open_for_completed_state ?? false
				};
			});
		},
		[t]
	);

	useEffect(() => {
		if (action === 'new' || i_list == undefined) {
			return;
		}
		sendHttpRequest(
			'POST',
			'/promotion/structure',
			{ i_business: iBusiness },
			navigate,
			dispatch
		).then((response) => {
			const templates: { key: string; value: string }[] = Array.from(
				new Set(
					response.data[0].map((row: { i_template: string; n_template: string }) => {
						return { key: row.i_template, value: row.n_template };
					})
				)
			);
			setAllTemplates(templates);

			const structures = response.data[0]
				.sort(compareOrderBy)
				.map((row: { json: string; i_template: string; order_by: number }) => {
					const data = JSON.parse(row.json)[0];
					return {
						i_template: row.i_template,
						order_by: row.order_by,
						general_inputs: convertToColumnDefinitions(data.general[0]),
						product_inputs: convertToColumnDefinitions(data.product[0]),
						item_inputs: convertToColumnDefinitions(data.items[0]),
						tabs: Object.entries(data.tabs[0]).map(([key, value]) => {
							return {
								name: humanizeString(key.substring(4)),
								key: key,
								columns: convertToColumnDefinitions((value as object[])[0])
							} as Tab;
						}),
						countries: data.countries
					} as PromotionStructure;
				});
			setStructures(structures);
		});
	}, [action, navigate, iBusiness, dispatch, convertToColumnDefinitions, i_list]);

	const countriesData = useMemo(() => {
		return structures[0]?.countries.reduce((acc: Map<string, PromotionCountry>, country) => {
			acc.set(country.i_country, country);
			return acc;
		}, new Map<string, PromotionCountry>());
	}, [structures]);

	const lsViewLevel = localStorage.getItem('viewLevel') ?? 'products';
	const viewLevel = (['products', 'summary'].includes(lsViewLevel) ? lsViewLevel : 'products') as
		| 'products'
		| 'summary';

	const viewLevelLabels = {
		products: 'products',
		summary: 'gp_calculator'
	};

	const compareTemplates = useCallback(
		(a: string, b: string) =>
			structures.findIndex((s) => s.i_template === a) -
			structures.findIndex((s) => s.i_template === b),
		[structures]
	);

	function compareOrderBy(a: { order_by: number }, b: { order_by: number }) {
		return a.order_by - b.order_by;
	}

	useEffect(() => {
		if (action === 'new') {
			setIsNew(true);
			setLoading(false);
			return;
		}
		if (i_list == undefined) {
			return;
		}
		if (
			list !== null &&
			!statusChanged &&
			!filtersChanged &&
			parseInt(i_list ?? '0') == list.i_list
		) {
			return;
		}
		if (window.location.search.length > 0) {
			setLoading(true);
			const urlParams = new URLSearchParams(window.location.search);

			sendHttpRequest(
				'GET',
				'/promotion/' + (urlParams.get('i_list') ?? '1'),
				null,
				navigate,
				dispatch
			).then((response) => {
				setOriginalDataLength(response.data[0][0].length);
				setData(
					response.data[0][0]
						.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
						.filter((row: Product) => row.i_template !== undefined)
						.map((row: Product) => {
							if (row.retail_price !== undefined && row.retail_price !== null) {
								row.retail_price = (row.retail_price as number).toFixed(2);
							} else {
								row.retail_price = '';
							}
							if (row.items === undefined || row.items === null) {
								row.items = [];
							}
							for (const item of row.items) {
								if (item.srp !== undefined) {
									item.srp = ((item.srp ?? 0) as number).toFixed(2);
								}
							}
							return row;
						})
						.filter(
							(row: Product) =>
								viewLevel === 'products' ||
								((row.product_price_code.includes(filterProductCode) ||
									row.product_short_code?.includes(filterProductCode)) &&
									row.items.some((item) => item.c_item.includes(filterCartonCode)))
						)
						.map((row: object) => {
							return {
								...row,
								id: randomHash()
							};
						})
				);
				setTemplates(
					Array.from(
						new Set(response.data[0][0].map((row: { i_template: string }) => row.i_template))
					)
				);
				setUsages(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { i_usg: string }) => row.i_usg ?? null)
						)
					)
				);
				setGpCategories(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { GP_group: string }) => row.GP_group ?? null)
						)
					)
				);
				setClusters(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { main_cluster: string }) => row.main_cluster ?? null)
						)
					)
				);

				const list = response.data[1][0][0];
				dispatch(setAllCountries(list.countries.split(',')));
				setCountries(list.countries.split(','));
				setList(list);
				setIBusiness(list.i_business ?? '');
				setCList(list.c_list ?? '');
				if (list.week_from != null) {
					setListWeekFrom(list.week_from);
				}
				if (list.week_to != null) {
					setListWeekTo(list.week_to);
				}
				setIsNew(list.week_from === null || list.week_to === null || list.c_list === null);
				setListStatus(list.i_list_status);
				setEditDisabledByStatus(!list.can_write);
				setCanClose(!!list.can_cancel_item);
				setGpSummaryRaw(JSON.parse(list.summary));
				setStatusChanged(false);
				setPromotionStatuses(response.data[1][0][0].available_statuses.split(','));
				setLoading(false);
				setFiltersChanged(false);
			});
		} else {
			setData([]);
			setStatusChanged(false);
		}
	}, [
		action,
		i_list,
		dispatch,
		navigate,
		viewLevel,
		statusChanged,
		listStatus,
		list,
		filterProductCode,
		filterCartonCode,
		filtersChanged
	]);

	useEffect(() => {
		sendHttpRequest('GET', '/businesses', {}, navigate, dispatch).then((response) => {
			setBusinesses(response.data[0]);
		});
	}, [dispatch, navigate]);

	useEffect(() => {
		if (gpSummaryRaw) {
			const toProcess = gpSummaryRaw.filter((gpsr) => countries.includes(gpsr.i_country));

			const summaryMap: Record<string, GpSummaryProcessed> = {};

			toProcess.forEach((summary) => {
				const { week, number_of_recruits, parties_number_forecast } = summary;

				if (summaryMap[week]) {
					summaryMap[week].number_of_recruits += number_of_recruits;
					summaryMap[week].parties_number_forecast += parties_number_forecast;
				} else {
					summaryMap[week] = {
						week,
						number_of_recruits,
						parties_number_forecast
					};
				}
			});

			setGpSummaryProcessed(Object.values(summaryMap));
		}
	}, [gpSummaryRaw, countries, viewLevel]);

	const [grouping, setGrouping] = useState(
		(localStorage.getItem('grouping') ?? 'template') as keyof typeof groupingLabels
	);
	const [groups, setGroups] = useState(templates.sort(compareTemplates));
	const groupingLabels = {
		channel: 'channel',
		cluster: 'main_cluster',
		gp: 'sub-cluster',
		usage: 'usage',
		template: 'template'
	};
	const allGroups = ['channel', 'cluster', 'gp', 'usage', 'template'];

	function getProductsFromGroup(group: string) {
		let res;

		switch (grouping) {
			case 'usage':
				res = data.filter(
					(row) => row.i_usg === group || (row.i_usg === undefined && group === null)
				);
				break;
			case 'gp':
				res = data.filter(
					(row) => row.GP_group === group || (row.GP_group === undefined && group === null)
				);
				break;
			case 'cluster':
				res = data.filter(
					(row) => row.main_cluster === group || (row.main_cluster === undefined && group === null)
				);
				break;
			case 'channel':
				res = data;
				break;
			default:
				res = data.filter((row) => row.i_template === group);
				break;
		}

		return res;
	}

	function submitListInfo() {
		if (cList === '') {
			alert('The "c_list" needs to be specified.');
			return;
		}
		if (listWeekFrom.length < 6 || listWeekTo.length < 6) {
			console.log(`"${listWeekFrom}, "${listWeekTo}"`);
			alert('Please specify the weeks as sequences of 6 digits.');
			return;
		}

		sendHttpRequest(
			'POST',
			'/promotion',
			{ i_business: iBusiness, c_list: cList, week_from: listWeekFrom, week_to: listWeekTo },
			navigate,
			dispatch
		).then((response) => {
			const i_list = response.data[0][0].i_list;
			if (!response.data[1].some((l: { t_l: string | null }) => l.t_l === 'E')) {
				setListInfoSaved(true);
				if (isNew) {
					setIsNew(false);
					localStorage.setItem('viewLevel', 'products');
					setGrouping('template');
					localStorage.setItem('grouping', 'template');
				}
				navigate('/list_detail?i_list=' + i_list);
			}
		});
	}

	function onStatusChange(newStatus: string) {
		setListStatus(newStatus);
		sendHttpRequest(
			'POST',
			'/promotion/' + (list?.i_list ?? '1'),
			{ status: newStatus },
			navigate,
			dispatch
		).then(() => {
			setListInfoSaved(true);
			setStatusChanged(true);
		});
	}

	function exportToXlsx(dense = false) {
		sendHttpRequest(
			'GET',
			'/promotion/file/' +
				(list?.i_list ?? '1') +
				'/' +
				iBusiness.toString() +
				'/' +
				i18n.language +
				(dense ? '?dense=true' : ''),
			null,
			navigate,
			dispatch,
			'blob'
		).then((response) => {
			const filename = response.headers['content-disposition'].split('"')[1];
			FileDownload(response.data, filename);
		});
	}

	const getTotalGpPercentageCallback = useCallback(() => {
		if (countries.length && list !== null && data.length) {
			getTotalGpPercentage(countries, list.i_list, navigate, dispatch).then((response: any) => {
				dispatch(setTotalGpPercentage(response.data.gp_percentage as number));
			});
		}
	}, [countries, data, dispatch, list, navigate]);

	useEffect(() => {
		switch (grouping) {
			case 'usage':
				setGroups(usages);
				break;
			case 'gp':
				setGroups(gpCategories);
				break;
			case 'cluster':
				setGroups(clusters);
				break;
			case 'channel':
				setGroups(['']);
				break;
			default:
				setGroups(templates.sort(compareTemplates));
				break;
		}
	}, [clusters, compareTemplates, gpCategories, grouping, setGrouping, templates, usages]);

	useEffect(() => {
		getTotalGpPercentageCallback();
	}, [countries, getTotalGpPercentageCallback]);

	useEffect(() => {
		setShowAddTemplateButton(
			grouping === 'template' &&
				viewLevel !== 'summary' &&
				!editDisabledByStatus &&
				listStatus !== 'COMPLETED'
		);
	}, [viewLevel, editDisabledByStatus, listStatus, grouping]);

	useEffect(() => {
		if (isNew) {
			dispatch(setTotalGpPercentage(null));
		}
	}, [dispatch, isNew]);

	if (isNew && !loading) {
		return (
			<div className="row justify-content-center pt-5 mt-5">
				<div className="col-12">
					<div className="d-flex justify-content-center">
						<div className="w-fit list-info-wrapper">
							<div className="mb-4">
								<h1 className="mb-5">{t('New promotion')}</h1>
								<p>{t('Please fill out and save the promotion details before continuing.')}</p>
							</div>
							<div className="d-flex">
								<ListInformationForm
									cList={cList}
									setCList={setCList}
									editDisabledByStatus={false}
									listInfoSaved={listInfoSaved}
									setListInfoSaved={setListInfoSaved}
									listWeekFrom={listWeekFrom}
									setListWeekFrom={setListWeekFrom}
									listWeekTo={listWeekTo}
									setListWeekTo={setListWeekTo}
									t={t}
									submitListInfo={submitListInfo}
									isNew={true}
									businesses={businesses}
									iBusiness={iBusiness}
									setIBusiness={setIBusiness}
									listStatus={'BUILD'}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			{loading && (
				<div className="row justify-content-center pt-5 mt-5">
					<Loading />
				</div>
			)}
			{!loading && (
				<form className="create-product-form">
					<div className="template-wrapper create-product-form-header flex-wrap">
						<div className="template-name">&nbsp;</div>
						<div className="promotion-header">
							<div className="d-flex w-100 mb-3">
								<div className="col-12 ms-4 d-flex list-info-wrapper justify-content-between align-items-start">
									<div className="d-flex">
										<ListInformationForm
											cList={cList}
											setCList={setCList}
											editDisabledByStatus={editDisabledByStatus}
											listInfoSaved={listInfoSaved}
											setListInfoSaved={setListInfoSaved}
											listWeekFrom={listWeekFrom}
											setListWeekFrom={setListWeekFrom}
											listWeekTo={listWeekTo}
											setListWeekTo={setListWeekTo}
											t={t}
											submitListInfo={submitListInfo}
											isNew={isNew}
											businesses={businesses}
											iBusiness={iBusiness}
											setIBusiness={setIBusiness}
											listStatus={listStatus}
										/>
									</div>
									<div className="d-flex ps-3">
										{gpSummaryProcessed && gpSummaryProcessed.length > 0 && (
											<GpSummary gpSummaryProcessed={gpSummaryProcessed} />
										)}

										{totalGpPercentage !== null && totalGpPercentage !== -1 && (
											<div className="ps-4 pt-2">
												<div className="fw-medium text-center">GP %</div>
												<div className="fs-4 color-primary-shade fw-medium text-center">
													{totalGpPercentage.toFixed(2)}%
												</div>
											</div>
										)}
									</div>
									<div className="pe-5 d-flex">
										<div className="me-0">
											<Dropdown align="end" className="status-dropdown">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-status">
													<div className="w-100">
														<div className="status-label">Status</div>
														<div className="d-flex justify-content-between">
															<span>
																{t((('promotion.status.' + listStatus) as string).toLowerCase())}
															</span>
															<i className="bi bi-chevron-down ms-2 d-inline-block" />
														</div>
													</div>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{promotionStatuses.map((s) => (
														<Dropdown.Item
															className="py-2"
															key={s}
															onClick={() => onStatusChange(s)}>
															{t((('promotion.status.' + s) as string).toLowerCase())}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										{!editDisabledByStatus && (
											<button
												className="btn btn-primary data-save"
												disabled={dataSaveStatus !== 'Save data'}
												onClick={(e) => e.preventDefault()}>
												{dataSaveStatus === 'Saving' && (
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"></span>
												)}
												{dataSaveStatus === 'Save data' && <i className="bi bi-floppy fs-4" />}
												{dataSaveStatus === 'Data saved' && <i className="bi bi-check-lg fs-4" />}
											</button>
										)}
									</div>
								</div>
							</div>
							<div className="d-flex w-100">
								<div className="col-12 ms-4 d-flex justify-content-between">
									<div className="d-flex text-nowrap">
										<div className="me-2p5">
											<Dropdown align="end" className="grouping-dropdown">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-grouping">
													<span>
														{t('promotion.groups.' + groupingLabels[grouping].toLowerCase())}
													</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{Object.entries(groupingLabels).map(([key, value]) => (
														<Dropdown.Item
															key={key}
															onClick={() => {
																localStorage.setItem('grouping', key);
																setGrouping(key as keyof typeof groupingLabels);
															}}>
															{t('promotion.groups.' + value.toLowerCase())}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="me-2p5">
											<Dropdown align="end" className="view-level-dropdown">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-view-level">
													<span>
														{t('promotion.view.' + viewLevelLabels[viewLevel].toLowerCase())}
													</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{Object.entries(viewLevelLabels).map(([key, value]) => (
														<Dropdown.Item
															key={key}
															onClick={() => {
																localStorage.setItem('viewLevel', key);
																window.location.reload();
															}}>
															{t('promotion.view.' + value.toLowerCase())}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="me-2p5 d-flex">
											{viewLevel !== 'summary' && (
												<div>
													<Dropdown>
														<Dropdown.Toggle variant="outline-primary" id="dropdown-omit-columns">
															<i className="bi bi-layout-text-sidebar-reverse"></i>
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{(role === 'ADMIN' || layouts.global.length > 0) && (
																<div className="px-3 py-1 layout-heading">
																	{t('promotion.fields.global_layouts')}
																</div>
															)}

															{role === 'ADMIN' && (
																<Dropdown.Item
																	className="color-primary-shade"
																	onClick={(e) => {
																		e.stopPropagation();
																		saveLayout(true);
																	}}>
																	<i className="bi bi-floppy me-3" />
																	{t('promotion.fields.save_global_layout')}
																</Dropdown.Item>
															)}

															{layouts.global.map((layout, i) => (
																<div key={i} className="btn-group d-flex">
																	<Dropdown.Item
																		className="layout-selector"
																		onClick={() => {
																			loadLayout(layout);
																		}}>
																		<i
																			className={
																				'bi bi-check-lg color-primary-shade me-3' +
																				(!currentLayout || currentLayout.name !== layout.name
																					? ' opacity-0'
																					: '')
																			}
																		/>
																		{layout.name}
																	</Dropdown.Item>
																	{role === 'ADMIN' && (
																		<Dropdown.Item
																			className="layout-trash text-end"
																			onClick={() => {
																				deleteLayout(layout, true);
																			}}>
																			<i className="bi bi-x-lg" />
																		</Dropdown.Item>
																	)}
																</div>
															))}

															<div className="px-3 pt-3 pb-1 layout-heading">
																{t('promotion.fields.custom_layouts')}
															</div>

															<Dropdown.Item
																className="color-primary-shade"
																onClick={(e) => {
																	e.stopPropagation();
																	saveLayout();
																}}>
																<i className="bi bi-floppy me-3" />
																{t('promotion.fields.save_current_layout')}
															</Dropdown.Item>

															{layouts.custom.map((layout, i) => (
																<div key={i} className="btn-group d-flex">
																	<Dropdown.Item
																		className="layout-selector"
																		onClick={() => {
																			loadLayout(layout);
																		}}>
																		<i
																			className={
																				'bi bi-check-lg color-primary-shade me-3' +
																				(!currentLayout || currentLayout.name !== layout.name
																					? ' opacity-0'
																					: '')
																			}
																		/>
																		{layout.name}
																	</Dropdown.Item>
																	<Dropdown.Item
																		className="layout-trash text-end"
																		onClick={() => {
																			deleteLayout(layout);
																		}}>
																		<i className="bi bi-x-lg" />
																	</Dropdown.Item>
																</div>
															))}

															<hr />

															<Dropdown.Item
																onClick={(e) => {
																	e.stopPropagation();
																	hideAllColumns();
																}}>
																{t('promotion.fields.hide_all_columns')}
															</Dropdown.Item>
															<Dropdown.Item
																onClick={(e) => {
																	e.stopPropagation();
																	showAllColumns();
																}}>
																{t('promotion.fields.show_all_columns')}
															</Dropdown.Item>

															<hr />

															{(structures[0]?.tabs ?? []).map((t, i) => (
																<Fragment key={i}>
																	{t.columns.map((col, col_index) => (
																		<Fragment key={col_index}>
																			<Dropdown.Item
																				onClick={(e) => {
																					e.stopPropagation();
																					toggleColumnVisibility(col.key);
																				}}
																				className={
																					omitColumns.includes(col.key) ? 'color-gray' : ''
																				}>
																				{omitColumns.includes(col.key) ? (
																					<i className="bi-eye-slash me-3" />
																				) : (
																					<i className="bi-eye me-3" />
																				)}
																				{col.name}
																			</Dropdown.Item>
																		</Fragment>
																	))}
																</Fragment>
															))}
														</Dropdown.Menu>
													</Dropdown>
												</div>
											)}
										</div>

										{viewLevel === 'products' && (
											<div className="me-2p5">
												<button
													type="button"
													className={
														filterProductCode.length > 0 || filterCartonCode.length > 0
															? 'btn btn-primary'
															: 'btn btn-outline-primary'
													}
													onClick={() => {
														setShowFilterModal(true);
														setFilterProductCodeInput(filterProductCode);
														setFilterCartonCodeInput(filterCartonCode);
													}}>
													<i
														className={
															'bi bi-search' +
															(filterProductCode.length > 0 || filterCartonCode.length > 0
																? ' me-2'
																: '')
														}
													/>
													{[filterProductCode, filterCartonCode]
														.filter((c) => c.length > 0)
														.join(', ')}
													{(filterProductCode.length > 0 || filterCartonCode.length > 0) && (
														<>
															{' '}
															({data.length}/{originalDataLength})
														</>
													)}
												</button>
											</div>
										)}
										<div className="btn-group me-2p5" role="group">
											{allCountries.length <= 4 ? (
												<>
													{allCountries.length > 1 && (
														<button
															type="button"
															className={
																'btn btn-outline-primary' +
																(countries.join('') === allCountries.join('') ? ' active' : '')
															}
															onClick={() => setCountries(allCountries)}>
															{t('promotion.fields.all')}
														</button>
													)}
													{allCountries.map((country) => (
														<button
															key={country}
															type="button"
															className={
																'btn btn-outline-primary' +
																(countries.length === 1 && countries.includes(country)
																	? ' active'
																	: '')
															}
															onClick={() => setCountries([country])}>
															{country}
														</button>
													))}
												</>
											) : (
												<Dropdown align="end" className="countries-dropdown">
													<Dropdown.Toggle variant="outline-primary">
														<span>{countries.length === 1 ? countries[0] : 'All countries'}</span>
														<i className="ms-2 bi-chevron-down"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => {
																setCountries(allCountries);
															}}>
															Show all countries
														</Dropdown.Item>

														<hr />

														{allCountries.map((country) => (
															<Dropdown.Item key={country} onClick={() => setCountries([country])}>
																{country}
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											)}
										</div>
										{showAddTemplateButton && (
											<button
												onClick={handleOpenTemplateModal}
												type="button"
												className="btn btn-primary ms-3">
												<i className="bi bi-plus-lg me-2" />
												{t('promotion.fields.add_template')}
											</button>
										)}
									</div>
									<div className="text-end pe-5">
										<button
											type="button"
											className="btn btn-outline-primary"
											onClick={() => exportToXlsx(true)}>
											<i className="me-2 bi bi-download" />
											.xlsx
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{(cList === '' || listWeekFrom === '' || listWeekTo === '' || !listInfoSaved) && (
						<Alert
							message="You need to fill out and save the promotion details before continuing!"
							type="danger"
							customClass="promotion-saving-alert"
						/>
					)}
					<div
						className={
							cList === '' || listWeekFrom === '' || listWeekTo === '' || !listInfoSaved
								? 'faded'
								: ' '
						}>
						<div className="row">
							{groups.map((g, i) => (
								<PromotionTemplate
									key={i}
									template={g}
									structures={structures}
									viewLevel={viewLevel}
									products={getProductsFromGroup(g)}
									allProducts={data}
									index={i}
									addProduct={addProduct}
									i_business={iBusiness}
									countries={countries}
									countriesData={countriesData}
									omitColumns={omitColumns}
									setOmitColumns={changeOmittedColumns}
									grouping={grouping}
									setDataSaveStatus={setDataSaveStatus}
									removeProduct={removeProduct}
									listWeekFrom={listWeekFrom}
									listWeekTo={listWeekTo}
									allGroups={allGroups}
									editDisabledByStatus={editDisabledByStatus}
									canClose={canClose}
									listStatus={listStatus}
									iList={list?.i_list ?? 1}
									triggerDataRefresh={triggerDataRefresh}
									isOnlyTemplate={groups.length == 1}
									showAll={originalDataLength <= 1}
								/>
							))}
						</div>
					</div>
				</form>
			)}
			{/* TODO show ALL below 10 */}

			<Modal show={showTemplateModal} onHide={handleCloseTemplateModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('promotion.fields.add_template')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Select
						name="template"
						label="Template"
						defaultValue={newTemplateSelect}
						onChange={(e) => setNewTemplateSelect(e.target.value)}
						required={true}
						options={
							new Map(
								allTemplates
									.filter((t) => !templates.includes(t.key))
									.map((t) => [t.key, t.key + ' - ' + t.value])
							)
						}
					/>
					<button type="button" onClick={addTemplate} className="btn btn-primary mt-3 w-100">
						{t('promotion.fields.add_template')}
					</button>
				</Modal.Body>
			</Modal>

			<Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{t('promotion.fields.filters')}</Modal.Title>
				</Modal.Header>

				<Modal.Body className="d-flex justify-content-center">
					<div className="filter-form">
						<div className="d-flex flex-wrap">
							<div className="filter-item me-3">
								<Input
									name={'product_code'}
									label={t('promotion.fields.product_code')}
									type="text"
									required={false}
									defaultValue={filterProductCodeInput}
									onChange={(e) => setFilterProductCodeInput(e.target.value)}
								/>
							</div>
							<div className="filter-item me-3">
								<Input
									name={'carton_code'}
									label={t('promotion.fields.stock_item')}
									type="text"
									required={false}
									defaultValue={filterCartonCodeInput}
									onChange={(e) => setFilterCartonCodeInput(e.target.value)}
								/>
							</div>
							<button type="button" className="btn btn-primary me-2" onClick={onFilterSubmit}>
								<i className="bi bi-search"></i>
							</button>
							<button type="button" className="btn btn-light" onClick={onFilterReset}>
								<i className="bi bi-arrow-counterclockwise"></i>
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
